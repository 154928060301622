@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito+Sans:ital,opsz,wght@0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;1,6..12,400;1,6..12,500&family=Open+Sans:wght@400;500;600;700&family=Roboto&display=swap");

$bg: #f9f9f9;
$color_button: #7b7b7b;
$error_color: #dc3545;
$success: #198754;
$header-height: 3rem;
$nav-width: 68px;
$first-color: #4723d9;
$first-color-light: #afa5d9;
$white-color: #fff;
$opacity_75: 0.75;
$opacity_1: 1;
$body-font: "Nunito", sans-serif;
$normal-font-size: 1rem;
$z-fixed: 100;
$bg: rgba(13, 110, 253);
* {
	box-sizing: border-box;
	&::after,
	&::before {
		box-sizing: border-box;
	}
}

body {
	background: rgb(255, 255, 255);
	background: -moz-linear-gradient(
		180deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(13, 110, 253, 0.11808473389355745) 100%
	);
	background: -webkit-linear-gradient(
		180deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(13, 110, 253, 0.11808473389355745) 100%
	);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(13, 110, 253, 0.11808473389355745) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#0d6efd", GradientType=1);
	background-repeat: no-repeat;
	min-height: 100dvh;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	appearance: textfield;
	-moz-appearance: textfield;
}
.required {
	color: #dc3545;
}

.body_class {
	position: relative;
	margin: $header-height 0 0 0;
	padding: 0 1rem;
	font-family: $body-font;
	font-size: $normal-font-size;
	transition: 0.5s;
}
a {
	text-decoration: none;
}
.header {
	width: 100%;
	height: $header-height;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	// padding: 0 1rem;
	background-color: $white-color;
	z-index: $z-fixed;
	transition: 0.5s;
	flex-wrap: nowrap;
}
.header_toggle {
	color: $bg;
	padding-left: 10px;
	font-size: 1.5rem;
	cursor: pointer;
}
.header_img {
	width: 35px;
	height: 35px;
	display: flex;
	justify-content: center;
	border-radius: 50%;
	overflow: hidden;
}
.header_img img {
	width: 40px;
}
.l-navbar {
	overflow-y: auto;
	position: fixed;
	top: 0;
	left: -30%;
	width: $nav-width;
	height: 100vh;
	background-color: $bg;
	padding: 0.5rem 1rem 0 0;
	transition: 0.5s;
	z-index: $z-fixed;
}
.nav {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
}
.nav_logo,
.nav_link {
	display: grid !important;
	grid-template-columns: max-content max-content !important;
	align-items: center !important;
	column-gap: 1rem !important;
	padding: 0.5rem 0 0.5rem 1.5rem !important;
}
.nav_logo {
	margin-bottom: 2rem !important;
}
.nav_logo-icon {
	font-size: 1.25rem;
	color: $white-color;
}
.nav_logo-name {
	color: $white-color;
	font-weight: 700;
}
.nav_link {
	position: relative !important;
	color: $white-color !important;
	opacity: $opacity_75;
	margin-bottom: 1.5rem !important;
	transition: 0.3s !important;
}
.nav_link:hover {
	color: $white-color !important;
	opacity: $opacity_1;
}
.nav_icon {
	font-size: 1.25rem !important;
}
.show {
	left: 0;
}
.body-pd {
	padding-left: calc($nav-width + 10px);
}
.active {
	color: $white-color;
	opacity: $opacity_1;
}
.active::before {
	content: "";
	position: absolute;
	left: 0;
	width: 2px;
	height: 32px;
	background-color: $white-color;
}
.height-100 {
	height: 100vh;
	padding-top: 20px;
	padding-left: 0;
}

.bg {
	background-color: $bg;
}

.full_scren {
	min-height: 100vh;
}

.file_uplod {
	cursor: pointer;
	padding: 12px 12px;
	border: 1px solid;
	background-color: $color_button;

	& > span {
		margin-left: 5px;
	}
}

.uplod_img {
	width: 140px;
	@media screen and (max-width: 985px) {
		width: 87px;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.custom-switch {
	input {
		width: 40px !important;
		height: 22px;
	}
}

.input_contaoner {
	&.error {
		input {
			border-color: $error_color;
		}
		textarea {
			border-color: $error_color;
		}
	}
}

.css-13cymwt-control {
	&.error {
		border-color: $error_color;
	}
}

.button_error {
	background-color: $error_color !important;
	border-color: $error_color !important;
}

.button_container {
	button {
		padding: 2px;
		color: initial;
	}
}

.button_svg {
	cursor: pointer;
	padding: 3px;
	border: solid 1px;
	border-radius: 5px;
	transition: all 0.3 ease;
	& .sucsses_boredr {
		border-color: #198754;
	}
	&.error_boredr {
		border-color: #dc3545;
	}
	&.clipboard_copy_border {
		border-color: $bg;
	}
	&:hover {
		box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
			rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	}
}

.helper_text {
	color: $error_color;
	padding-left: 10px;
}

.textarea {
	resize: none;
}

.form-label {
	font-size: 18px;
}

.form-check-label {
	cursor: pointer;
}

.form-check-input {
	margin-bottom: 3px;
}

// .btn {
// 	// &.btn-primary {
// 	// 	background-color: hsl(240deg 100% 39.7%) !important;
// 	// 	&:hover {
// 	// 		background-color: hsl(240, 49%, 50%) !important;
// 	// 	}
// 	// }
// 	// // &:hover {
// 	// // }
// 	cursor: pointer;
// 	transition: all ease 0.3s;
// 	font-size: 1.23rem !important;
// 	min-width: 145px;
// 	min-height: 44px;
// }
.loader-line {
	width: 100%;
	height: 3px;
	position: relative;
	overflow: hidden;
	background-color: #ddd;
	// margin: 100px auto;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}

.loader-line:before {
	content: "";
	position: absolute;
	left: -50%;
	height: 3px;
	width: 40%;
	background-color: hsl(240deg 100% 39.7%);
	-webkit-animation: lineAnim 1s linear infinite;
	-moz-animation: lineAnim 1s linear infinite;
	animation: lineAnim 1s linear infinite;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}

@keyframes lineAnim {
	0% {
		left: -40%;
	}

	50% {
		left: 20%;
		width: 80%;
	}

	100% {
		left: 100%;
		width: 100%;
	}
}

.no-data-block {
	display: flex;
	justify-content: center;
	margin-top: 50px;
	flex-flow: column;
	align-items: center;

	svg {
		width: 300px;
	}
}

.max-line-1 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.max-line-2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.max-line-3 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.custom-datepicker {
	/* Your custom styles for the datepicker */
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-calendar-container {
	/* Your custom styles for the calendar container */
	position: absolute;
	z-index: 999;
	/* Adjust the z-index as needed */
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 20px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	padding: 10px;

	&:focus-visible {
		background-color: var(--bs-body-bg);
		border-color: #86b7fe;
		outline: 0;
		box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
	}
}

.react-datepicker-wrapper {
	& input {
		&:focus-visible {
			background-color: var(--bs-body-bg);
			border-radius: var(--bs-border-radius);
			border-color: #86b7fe;
			outline: 0;
			box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
		}
	}

	&:focus-visible {
		background-color: var(--bs-body-bg);
		border-radius: var(--bs-border-radius);
		border-color: #86b7fe;
		outline: 0;
		box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
	}
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper {
	margin-left: auto;
	margin-right: auto;
}

.mySwiper {
	z-index: 0 !important;
	padding: 8px !important;

	.swiper-button-prev:after,
	.swiper-button-next:after {
		font-size: 20px !important;
	}

	& > .swiper-pagination {
		& > .swiper-pagination-bullet.swiper-pagination-bullet-active {
			transition: all 0.4ms;
			width: 22px;
			border-radius: 9px;
		}
	}

	& > .swiper-wrapper {
		z-index: 0;
	}
}
.coustm_card {
	border: none !important;
	max-width: 32rem;
	min-width: 15rem !important;
	width: 100%;
}
.link {
	transition: all 0.5s;
	cursor: pointer;
	color: #0d6efd !important;
	&:hover {
		text-decoration: underline !important;
	}
}
.show {
	right: -53px;
}
.sign_container {
	min-height: calc(100vh - 70px);
	display: flex;
	justify-content: center;
	align-items: center;
}
.scale-up-center {
	-webkit-animation: scale-up-center 0.6s cubic-bezier(0.18, 0.89, 0.32, 1.28)
		infinite alternate both;
	animation: scale-up-center 0.6s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite
		alternate both;
}
@-webkit-keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
.congratulations_modal {
	.modal-content {
		min-height: 300px;
		padding: 16px 30px 30px 30px;
		.congratulations_modal_star {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			padding: 8px;
			top: -108px;
			right: 50%;
			transform: translate(50%, 50%);
		}
		.congratulations_modal_title {
			text-align: center;
			font-family: "Dancing Script", cursive;
			padding-bottom: 8px;
		}
	}
}
@media screen and (max-width: 768px) {
}
@media screen and (min-width: 768px) {
	.body_class {
		margin: calc($header-height + 1rem) 0 0 0;
		padding-left: calc($nav-width + 10px);
	}
	.header {
		height: calc($header-height + 1rem);
		padding: 0 0 0 $nav-width;
	}
	.header_img {
		width: 40px;
		height: 40px;
	}
	.header_img img {
		width: 45px;
	}
	.l-navbar {
		left: 0;
		padding: 1rem 1rem 0 0;
	}
	.show {
		width: calc($nav-width + 156px);
	}
	.height-100 {
		padding-left: 10px;
	}
	.body-pd {
		padding-left: calc($nav-width + 156px);
	}
}
.game-folders {
	display: flex;
	align-items: center;
	gap: 8px;
}
.game-folder-list {
	transition: all 0.3;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}
.game-folder-button {
	display: flex !important;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	p {
		margin: 0;
		padding: 0;
	}
	&.active_button {
		border-color: #f7c963;
		color: #f7c963;
	}
}
.geme-modal-image {
	cursor: pointer;
	transition: all 0.3ms;
	&:hover {
		border-color: #198754 !important;
	}
	&.active {
		border-color: #198754 !important;
	}
}
@media screen and (max-height: 532px) and (orientation: landscape) {
	.nav_link {
		margin-bottom: 0 !important;
	}
}

.mb-2-3,
.my-2-3 {
	margin-bottom: 2.3rem;
}
.card_user {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}

section {
	padding: 120px 0;
	overflow: hidden;
	background: #fff;
}
.mb-2-3,
.my-2-3 {
	margin-bottom: 2.3rem;
}

.section-title {
	font-weight: 600;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 10px;
	position: relative;
	display: inline-block;
}
.text-primary {
	color: #ceaa4d !important;
}
.text-secondary {
	color: #15395a !important;
}
.font-weight-600 {
	font-weight: 600;
}
.display-26 {
	font-size: 1.3rem;
}

@media screen and (min-width: 992px) {
	.p-lg-7 {
		padding: 4rem;
	}
}
@media screen and (min-width: 768px) {
	.p-md-6 {
		padding: 3.5rem;
	}
}
@media screen and (min-width: 576px) {
	.p-sm-2-3 {
		padding: 2.3rem;
	}
}
.p-1-9 {
	padding: 1.9rem;
}

.bg-secondary {
	background: #15395a !important;
}
@media screen and (min-width: 576px) {
	.pe-sm-6,
	.px-sm-6 {
		padding-right: 3.5rem;
	}
}
@media screen and (min-width: 576px) {
	.ps-sm-6,
	.px-sm-6 {
		padding-left: 3.5rem;
	}
}
.pe-1-9,
.px-1-9 {
	padding-right: 1.9rem;
}
.ps-1-9,
.px-1-9 {
	padding-left: 1.9rem;
}
.pb-1-9,
.py-1-9 {
	padding-bottom: 1.9rem;
}
.pt-1-9,
.py-1-9 {
	padding-top: 1.9rem;
}
.mb-1-9,
.my-1-9 {
	margin-bottom: 1.9rem;
}
@media (min-width: 992px) {
	.d-lg-inline-block {
		display: inline-block !important;
	}
}
.rounded {
	border-radius: 0.25rem !important;
}

.publicKey {
	cursor: pointer;
	transition: all 0.3;
	&:hover {
		text-decoration: underline;
	}
}
